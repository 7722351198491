import { shallowEqual, useSelector } from "react-redux";
import {selectContextEntityName} from "../state/slices/CoreEntity";
import {useCoreEntityContext} from "./useCoreEntitySlice";

const useCurrentFacility = () => {
  const useCoreEntitySlice = useCoreEntityContext();


  const facilityID = useSelector(
    (state) => useCoreEntitySlice ? state.coreEntities?.ContextID : state.entities?.ContextID,
    shallowEqual
  );

  const facilityName = useSelector((state) => useCoreEntitySlice ? selectContextEntityName(state) : state.entities?.Context?.name);

  return {
    facilityID: facilityID,
    facilityName: facilityName ?? "Facilities",
  };
};

export default useCurrentFacility;
