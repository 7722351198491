import { useSelector, shallowEqual } from "react-redux";
import { GetAllScopedPermissions } from "../state/slices/entities";
import {useCoreEntityContext} from "./useCoreEntitySlice";
import {selectAllScopedPermissions} from "../state/slices/CoreEntity";

const useScopedPermissions = () => {
  const useCoreEntitySlice = useCoreEntityContext();

  return useSelector((state) => {
    return useCoreEntitySlice ? selectAllScopedPermissions(state) : GetAllScopedPermissions(state?.entities?.EntityList);
  }, shallowEqual);
};

export default useScopedPermissions;
