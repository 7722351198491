import { useCallback } from "react";
import { shallowEqual, useSelector } from "react-redux";
import moment from "moment";

import * as c from "../constants";
import useHubContext from "./useHubContext";
import { FindEntity } from "../state/slices/entities";
import { FindFacilityOfEntity } from "../state/slices/entityScope";
import {useCoreEntityContext} from "./useCoreEntitySlice";
import {selectEntityById} from "../state/slices/CoreEntity";

export const useGate = (deviceID, auditActions) => {
  const { portalHub } = useHubContext();
  const useCoreEntitySlice = useCoreEntityContext();

  const availableEntities = useSelector((state) => state.entityScope?.available, shallowEqual);
  const currentUser = useSelector((state) => state.user, shallowEqual);
  const device = useSelector(
    (state) =>useCoreEntitySlice ? selectEntityById(state, deviceID) : FindEntity(state.entities?.EntityList ?? [], deviceID),
    shallowEqual
  ) ?? null;

  const isGateOpen = useCallback(() => {
    return device?.typeid === c.ENTITY_TYPE.Device
      ? device?.state?.Peripherals?.Gate?.Open
      : false;
  }, [device]);

  const getGateActionAsEnum = useCallback((mode) => {
    return mode === "pulse"
      ? c.CALLCENTER_ACTIONS.GATE_VEND
      : isGateOpen()
        ? c.CALLCENTER_ACTIONS.GATE_CLOSED
        : c.CALLCENTER_ACTIONS.GATE_OPENED;
  }, [isGateOpen]);

  const getGateActionAsString = useCallback((mode, forEndUser) => {
    return mode === "pulse"
      ? forEndUser ? "cycle" : "vend"
      : isGateOpen() ? "close" : "open";
  }, [isGateOpen]);

  /**
   * Sends a notification to execute the specified action for the device supplied to the hook
   * @param {string} action The string of the action being performed
   * @param {string} timeOfAction The time of the action formatted: "YYYY-MM-DDTHH:mm:ssZ". If not supplied, set to now.
   */
  const sendGateAction = useCallback(
    async (action, timeOfAction) => {
      if (device?.typeid !== c.ENTITY_TYPE.Device) {
        return;
      }

      // send message for devices to execute the device action
      await portalHub.invoke(c.PORTAL_TRIGGER, {
        entityID: deviceID,
        topic: c.GATE_TOPIC,
        method: action,
        action: action,
        message: "{}"
      });

      if (auditActions) {
        // send message to audit the gate action
        const facility = FindFacilityOfEntity(availableEntities, deviceID);
        await portalHub.invoke(c.PORTAL_TRIGGER, {
          entityID: facility.id,
          topic: "AUDIT_GATE_ACTION",
          method: action,
          action: action,
          message: JSON.stringify({
            user: currentUser.Username,
            userID: currentUser.UserID,
            timeOfChange: timeOfAction ?? moment().format("YYYY-MM-DDTHH:mm:ssZ"),
            deviceID: deviceID,
            deviceName: device.name ?? "Device",
          }),
        });
      }
  }, [deviceID, auditActions, currentUser, device]);

  return {
    isGateOpen,
    getGateActionAsEnum,
    getGateActionAsString,
    sendGateAction,
  };
};
