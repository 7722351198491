import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { useSelector } from "react-redux";

const deviceListeningContext = createContext();

export const useListening = () => {
  return useContext(deviceListeningContext);
};


//TODO JonathanPayares this will be going away
// This Context is for the context is for pages that have deeply nested components that need to listen to devices
export const ListeningProvider = ({ children }) => {
  const currentContext = useSelector((state) => state.entities.Context);
  const [listeningEntities, setListeningEntities] = useState([]);
  const listeningEntitiesRef = useRef(listeningEntities);
  const callCenterQueue = useSelector((state) => state.callcenter.Queue);

  useEffect(() => {
    listeningEntitiesRef.current = listeningEntities;
  }, [listeningEntities]);

  useEffect(() => {
    const updatedEntities = callCenterQueue?.reduce((acc, call) => {
      if (call?.deviceID && !acc.includes(call.deviceID)) {
        acc.push(call.deviceID);
      }
      return acc;
    }, []);

    if (updatedEntities && updatedEntities.length > 0) {
      setListeningEntities((prev) => [
        ...new Set([...prev, ...updatedEntities]),
      ]);
    }
  }, [callCenterQueue]);

  useEffect(() => {
    const collectDeviceIds = (children, acc, visited) => {
      children.forEach((child) => {
        if (visited.has(child.entityid)) return;
        visited.add(child.entityid);

        if (child.details.type === "Device" && child.entityid && !acc.includes(child.entityid)) {
          acc.push(child.entityid);
        }

        if (child.children && child.children.length > 0) {
          collectDeviceIds(child.children, acc, visited);
        }
      });
    };

    if (!currentContext?.children) return;

    const currentContextDeviceIds = [];
    const visited = new Set();
    collectDeviceIds(currentContext.children, currentContextDeviceIds, visited);

    // Only update the state if there are new device IDs to add
    setListeningEntities((prev) => {
      const newDeviceIds = currentContextDeviceIds.filter(id => !prev.includes(id));
      if (newDeviceIds.length > 0) {
        return [...new Set([...prev, ...newDeviceIds])];
      }
      return prev;
    });
  }, [currentContext]);

  const isBeingListened = useCallback((entityId) => {
    if (
      !listeningEntitiesRef.current ||
      !Array.isArray(listeningEntitiesRef.current)
    ) {
      return false;
    }

    return listeningEntitiesRef.current.includes(entityId);
  }, []);
  return (
    <deviceListeningContext.Provider
      value={{ listeningEntities, isBeingListened }}
    >
      {children}
    </deviceListeningContext.Provider>
  );
};
