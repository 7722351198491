import { useSelector, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import {selectContextEntity} from "../state/slices/CoreEntity";
import {useCoreEntityContext} from "./useCoreEntitySlice";

const useCurrentFacilityTimezone = () => {
  const defaultFormat = "MM/DD/YYYY hh:mm a";
  const useCoreEntitySlice = useCoreEntityContext();

  const timeZone = useSelector((state) => {
    const tz = useCoreEntitySlice ? selectContextEntity(state).details?.timezone : state.entities.Context?.details?.timezone;
    return tz || moment.tz.guess();
  },shallowEqual);

  /**
   * Converts the given date time to the facility's timezone shifting the time as appropriate.
   */
  const convertUtcDate = (dt) => {
    return moment.utc(dt).tz(timeZone);
  };

  const formatDt = (momentDt, format) => {
    if (format) {
      return momentDt.format(format);
    }
    return momentDt.format(defaultFormat);
  };

  /**
   * Converts the given date time to the facility's timezone shifting the time as appropriate and
   * returning it formatted as specified.
   */
  const convertUtcDateToFormat = (dt, format) => {
    return formatDt(convertUtcDate(dt), format);
  };

  return {
    timeZone: timeZone,
    convertUtcDate: convertUtcDate,
    convertUtcDateToFormat: convertUtcDateToFormat,
    format: formatDt,
  };
};

useCurrentFacilityTimezone.defaultProps = {
  timeZone: "",
  convertUtcDate: () => {},
  convertUtcDateToFormat: () => {},
  format: () => {},
};

useCurrentFacilityTimezone.propTypes = {
  timeZone: PropTypes.string,
  convertUtcDate: PropTypes.func,
  convertUtcDateToFormat: PropTypes.func,
  format: PropTypes.func,
};

export default useCurrentFacilityTimezone;
