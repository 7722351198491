/* State */
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useEffect } from "react";
import _ from "lodash";
import { fetchFlags } from "../../state/slices/featureflags/featureflags";
import {useCoreEntityContext} from "../useCoreEntitySlice";


const useFeatureFlag = (featurePath) => {
  const dispatch = useDispatch();
  const useCoreEntitySlice = useCoreEntityContext();

  const facilityID = useSelector(
	(state) =>useCoreEntitySlice ? state.coreEntities?.ContextID :  state.entities?.ContextID,
	shallowEqual
  );
  const entityFlag = !!useSelector(
    state => state.featureFlags?.flags?.includes(featurePath)
  );

	const needsReload = useSelector(
		state => !state.featureFlags?.isLoading && !state.featureFlags
	);


	useEffect(() => {
		if (needsReload) {
			dispatch(fetchFlags(facilityID));
		}
	}, [needsReload])

  return entityFlag;
}

const useFeatureFlags = () => {
	const dispatch = useDispatch();
	const useCoreEntitySlice = useCoreEntityContext();

	const facilityID = useSelector(
		(state) =>useCoreEntitySlice ? state.coreEntities?.ContextID :  state.entities?.ContextID,
		shallowEqual
	);
	const entityFlags = useSelector(
	state => state.featureFlags?.flags,
	shallowEqual
	);

	const needsReload = useSelector(
		state => !state.featureFlags?.isLoading && !state.featureFlags
	);

	useEffect(() => {
		if (needsReload) {
			dispatch(fetchFlags(facilityID));
		}
	}, [needsReload])

	return entityFlags;
}

export { useFeatureFlag, useFeatureFlags };