import { isUndefined } from "lodash";
import moment from "moment-timezone";
import { useSelector, shallowEqual } from "react-redux";
import { FindNearestFacilityFromEntity } from "../../state/slices/entities";
import PropTypes from "prop-types";
import {useCoreEntityContext} from "../useCoreEntitySlice";
import {selectNearestFacilityFromEntity} from "../../state/slices/CoreEntity";

const useDeviceTimezone = (entityId) => {
  const useCoreEntitySlice = useCoreEntityContext();

  const timezone = useSelector((state) => {
    const entity = useCoreEntitySlice ? selectNearestFacilityFromEntity(entityId)(state) :  FindNearestFacilityFromEntity(
      state.entities?.EntityList ?? [],
      entityId
    );
    return entity?.details?.timezone
      ? entity.details.timezone
      : moment.tz.guess();
  }, shallowEqual);

  /**
   * Converts the given date time to the device's timezone shifting the time as appropriate.
   */
  const convertToDeviceTimezone = (datetime) => {
    const dateToBeConverted = moment.utc(datetime);
    return isUndefined(timezone)
      ? datetime
      : moment.tz(dateToBeConverted, timezone);
  };

  return {
    timezone: timezone,
    convertToDeviceTimezone: convertToDeviceTimezone,
  };
};

useDeviceTimezone.defaultProps = {
  deviceTimezone: "",
  convertToDeviceTimezone: () => {},
};

useDeviceTimezone.propTypes = {
  deviceTimezone: PropTypes.string,
  convertToDeviceTimezone: PropTypes.func,
};

export default useDeviceTimezone;
