import { shallowEqual, useSelector } from "react-redux";
import { GetAllDevicesIds } from "../state/slices/entities";
import { selectAllDeviceIds } from "../state/slices/CoreEntity";
import { useCoreEntityContext } from "./useCoreEntitySlice";

const useDeviceIDs = () => {
  const useCoreEntitySlice = useCoreEntityContext();

  return useSelector(
      (state) => useCoreEntitySlice ? selectAllDeviceIds(state) : GetAllDevicesIds(state.entities?.EntityList ?? []),
      shallowEqual
  );
};

export default useDeviceIDs;
