import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import AuthProvider from "./providers/AuthProvider";
import SettingsProvider from "./providers/SettingsProvider";
import HubProvider from "./providers/HubProvider";
import EntityProvider from "./providers/EntityProvider.js";
import ReportProvider from "./providers/ReportProvider";
import GroupProvider from "./providers/GroupProvider";
import ConfirmationDialogProvider from "./hooks/useConfirmationDialog";
import ThemeProvider from "./providers/ThemeProvider";
import AppStoreProvider from "./providers/AppStore/AppStoreProvider";
import {withLDProvider} from "launchdarkly-react-client-sdk";
import { CoreEntityProvider } from "./hooks/useCoreEntitySlice";

import "animate.css";

const render = (Component) => {
  ReactDOM.render(<Component/>, document.getElementById("root"));
};

const ProviderSetup = () => {
  return <ThemeProvider>
    <CoreEntityProvider>
      <AuthProvider>
        <HubProvider>
          <EntityProvider>
            <SettingsProvider>
              <AppStoreProvider>
                <ConfirmationDialogProvider>
                  <ReportProvider>
                    <GroupProvider>
                      <App/>
                    </GroupProvider>
                  </ReportProvider>
                </ConfirmationDialogProvider>
              </AppStoreProvider>
            </SettingsProvider>
          </EntityProvider>
        </HubProvider>
      </AuthProvider>
    </CoreEntityProvider>
  </ThemeProvider>
};

const renderWithLD = (Component) => {
  render(withLDProvider({
    clientSideID: process.env.LAUNCH_DARKLY_CLIENT_ID,
  })(Component));
}

renderWithLD(ProviderSetup);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
